<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <c-card title="LBLBASEINFO" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-3">
              <c-plant
                :required="true"
                :disabled="disable"
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="popupParam.plantCd"
              />
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="안전용품/보호구 지급현황"
        tableId="table"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        gridHeight="600px"
        selection="multiple"
        rowKey="safetySuppliesGiveId"
        :plantCd="popupParam.plantCd"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <div>
            <q-btn-group outline>
              <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addrowGive" />
              <c-btn v-if="editable" label="LBLSAVE" icon="save" @btnClicked="saveGive" />
              <c-btn v-if="editable" label="LBLREMOVE" icon="remove" @btnClicked="removeGive" />
            </q-btn-group>
          </div>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-give-manage',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        giveDt: '',
        safetySuppliesGiveId: '',
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'vendorCd',
            field: 'vendorCd',
            label: '수령 업체',
            style: 'width:300px',
            align: 'left',
            type: 'vendor',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'giveDt',
            field: 'giveDt',
            label: '지급일',
            align: 'center',
            setHeader: true,
            type: 'date',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'safetySuppliesTypeName',
            field: 'safetySuppliesTypeName',
            label: '구분',
            style: 'width:150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'safetySuppliesName',
            field: 'safetySuppliesName',
            label: '품목명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'unitName',
            field: 'unitName',
            label: 'LBLUNIT',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'giveCount',
            field: 'giveCount',
            label: '지급수량',
            setHeader: true,
            style: 'width:120px',
            align: 'right',
            type: 'number',
            sortable: false,
          },
          {
            name: 'usePlace',
            field: 'usePlace',
            label: '사용처',
            style: 'width:250px',
            align: 'left',
            setHeader: true,
            type: 'text',
            sortable: false,
          },
          {
            name: 'recieveUserName',
            field: 'recieveUserName',
            label: '수령인',
            setHeader: true,
            align: 'center',
            style: 'width:200px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'giveUserName',
            field: 'giveUserName',
            setHeader: true,
            label: '지급자',
            style: 'width:120px',
            align: 'center',
            type: 'user',
            sortable: false,
          },
        ],
        data: [],
      },
      typeItems: [
        { code: 'Y', codeName: '전체' },
        { code: 'N', codeName: '일자별' },
      ],
      searchParam: {
        plantCd: null,
        giveDt: '',
        safetySuppliesGiveId: '',
      },
      editable: true,
      disable: false,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.listUrl = selectConfig.sas.give.list.url;
      this.getList();
    },
    getList() {
      if (this.popupParam.giveDt != '' || this.popupParam.safetySuppliesGiveId != '') {
        this.disable = true;
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.popupParam.plantCd,
          giveDt: this.popupParam.safetySuppliesGiveId ? '' : this.popupParam.giveDt,
          safetySuppliesGiveId: this.popupParam.safetySuppliesGiveId,
        };
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.grid.data.push({
            editFlag: 'C',
            safetySuppliesGiveId: uid(),  // 안전용품 지급 일련번호
            plantCd: this.popupParam.plantCd,  // 공사현장
            safetySuppliesId: item.safetySuppliesId,  // 안전용품 일련번호
            safetySuppliesName: item.safetySuppliesName,  // 안전용품 일련번호
            safetySuppliesTypeCd: item.safetySuppliesTypeCd,
            safetySuppliesTypeName: item.safetySuppliesTypeName,
            giveDt: this.popupParam.giveDt ? this.popupParam.giveDt : this.$comm.getToday(),  // 지급일
            giveCount: '',  // 지급수량
            vendorCd: '',  // 지급대상 업체
            usePlace: '',  // 사용처
            recieveUserName: '',  // 수령자
            giveUserId: this.$store.getters.user.userId,  // 지급 사용자ID
            giveUserName: this.$store.getters.user.userName,
            unitName: item.unitName
          })
        });
      }
    },
    addrowGive() {
      
    },
    saveGive() {
      let checkItem = ['vendorCd', 'giveCount', 'recieveUserName']
      let isConti = true;
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '필수 입력값을 입력해 주세요. [지급대상 업체, 지급수량, 수령자]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
          if (item.editFlag == 'C') {
            item.plantCd = this.popupParam.plantCd;
          }
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = transactionConfig.sas.give.save.url;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.$emit('closePopup');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeGive() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.sas.give.delete.url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getOrgList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  },
};
</script>
